import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import ReactSelect from '../../common/ReactSelect';
import { isEqual, maxBy, minBy, map, capitalize, findIndex, find, cloneDeep } from 'lodash';

import config from '../../../config/config';
import CSV from '../../../helpers/CSVExport';
import { convertToThousands } from '../../../helpers/NumberFormatter';
import Box from '../../common/Box';
import Tooltip from "../../common/Tooltip";
import Entity from './Entity';
import EntityTable from './EntityTable';
import EntityVendors from './EntityVendors';
import EntityTimeline from './EntityTimeline';
import PercentageValue from '../../common/PercentageValue';
import TrendDirection from '../../common/TrendDirection';
import ExportModal from '../../common/ExportModal';
import LoadingMessage from '../../../helpers/LoadingMessage';
import PlaylistAnalysisLink from "../../common/Datagrid/PlaylistAnalysisLink";
import ConfirmClick from '../../common/ConfirmClick';

import { imprintsActions } from '../../../data/actions/imprints';
import { artistsActions } from '../../../data/actions/artists';
import { productsActions } from '../../../data/actions/products';
import { projectsActions } from '../../../data/actions/projects';
import { playlistsActions } from '../../../data/actions/playlists';
import { tracksActions } from '../../../data/actions/tracks';

var cardSvg = require('!svg-inline-loader!../../../../public/img/card.svg');
var tableSvg = require('!svg-inline-loader!../../../../public/img/table.svg');
var lineSvg = require('!svg-inline-loader!../../../../public/img/line.svg');
var vendorSvg = require('!svg-inline-loader!../../../../public/img/vendor-chart.svg');
var barSvg = require('!svg-inline-loader!../../../../public/img/chart-bar.svg');
var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');
var xlsxSvg = require('!svg-inline-loader!../../../../public/img/xlsx.svg');

class EntitiesList extends React.Component{

    options = [
       {label: 'Top Total Income', field: 'total_income', dir: 'DESC'},
       {label: 'Top Total Quantity', field: 'total_quantity', dir: 'DESC'},
       {label: 'Top Physical Income', field: 'physical_income', dir: 'DESC'},
       {label: 'Top Physical Quantity', field: 'physical_quantity', dir: 'DESC'},
       {label: 'Top Digital Income', field: 'digital_income', dir: 'DESC'},
       {label: 'Top Digital Quantity', field: 'digital_quantity', dir: 'DESC'},
       {label: 'Top NR Income', field: 'nr_income', dir: 'DESC'},
       {label: 'Top NR Quantity', field: 'nr_quantity', dir: 'DESC'},       
       {label: 'Bottom Total Income', field: 'total_income', dir: 'ASC'},
       {label: 'Bottom Total Quantity', field: 'total_quantity', dir: 'ASC'},
       {label: 'Bottom Physical Income', field: 'physical_income', dir: 'ASC'},
       {label: 'Bottom Physical Quantity', field: 'physical_quantity', dir: 'ASC'},
       {label: 'Bottom Digital Income', field: 'digital_income', dir: 'ASC'},
       {label: 'Bottom Digital Quantity', field: 'digital_quantity', dir: 'ASC'},
       {label: 'Bottom NR Income', field: 'nr_income', dir: 'ASC'},
       {label: 'Bottom NR Quantity', field: 'nr_quantity', dir: 'ASC'},                      
       /*
       {label: 'Most Listeners', field: 'curr_listeners', dir: 'DESC'},
       {label: 'Least Listeners', field: 'curr_listeners', dir: 'ASC'},
       {label: 'Listeners Spike', field: 'listeners_diff', dir: 'DESC'}
       */
    ];
    
    limits = [10, 25, 50]; // , 100, 'all'];

    constructor(props) {
        super(props);
        
        const defaultView = this.props.defaultView || 'table';
        
        this.state = {
            view: defaultView,
            currentExpandedId: null,
            sort: this.options[0],
            filter: this.getInitialFilter(props),
            page: 0
        }
        this.setChartView = this.setChartView.bind(this);
        this.expand = this.expand.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.setSort = this.setSort.bind(this);
        this.getDateRange = this.getDateRange.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
        this.exportToXls = this.exportToXls.bind(this);
        this.getTimeseries = this.getTimeseries.bind(this);
        this.getLoading = this.getLoading.bind(this);
        this.getLimit = this.getLimit.bind(this);
        this.setLimit = this.setLimit.bind(this);
        this.changePage = this.changePage.bind(this);
        this.getResults = this.getResults.bind(this);
    }

    getInitialFilter(props) {
        if(props.parentEntity && props.parentEntityIDs) {
            return [{id: props.parentEntity, value: props.parentEntityIDs}, {id: 'limit', value: this.limits[0]}];
        }
        return [{id: 'limit', value: this.limits[0]}];
    }

    componentDidMount(){
        this.props.getEntities({sorted: this.state.sort, filtered: this.state.filter, page: this.state.page});
    }

    loadAllData(limit) {
        let filter = cloneDeep(this.state.filter);
        let limitKeyIndex = findIndex(filter, item=>item.id == 'limit');
        filter[limitKeyIndex].value = limit;
        filter.page = 0;
        return this.props.getEntities({sorted: this.state.sort, filtered: filter, page: filter.page}, false); 
    }
    
    componentWillReceiveProps(nextProps){
        if(nextProps.filter && nextProps.filter.global){
            
            if(this.props.filter.global!==undefined && this.props.filter.global.hasOwnProperty('territories') && !isEqual(nextProps.filter.global, this.props.filter.global)) {
                this.props.getEntities({sorted: this.state.sort, filtered: this.state.filter, page: this.state.page}, false);
            }
        }
        const { entity } = this.props;
        if(nextProps[entity] && nextProps[entity].forceLoadAllData && this.props[entity] && !this.props[entity].forceLoadAllData) {
            console.log('loading export data for ' + entity);
            this.loadAllData(config.fullCSVThreshold);
        }
    }
    
    getTimeseries(){
        //this.props.getTimeseries({sorted: this.state.sort, filtered: this.state.filter});
        let data = this.getResults();
        if(data && data.length) {
            const IDField = (this.props.entity == 'tracks') ? 'isrc' : 'id';
            const ids = map(data, IDField).filter(id=>id);
            let filtered = Object.assign([], this.state.filter);
                filtered.push({id:'ids', value:ids});
            return this.props.getTimeseries({sorted: this.state.sort, filtered});
        }
    }

    exportToCsv(full = false) {
        let maxData = config.fullCSVThreshold, 
            dataCall;
        
        if(full) { // this.props.parentEntity && this.props.parentEntityIDs
            dataCall = this.loadAllData(maxData);
        }
        else {
            dataCall = Promise.resolve();
        }
            
        const filename = CSV.CSVHeader(`top_${this.props.entity}`, this.state.sort.field, this.props.filter.global, this.props.parentEntityTitle);
        dataCall.then(()=>this.getResults('top', maxData)).then(data=>CSV.CSVExport(data, {filename}, `top_${this.props.entity}`));
        //let data = this.getResults();
        //return CSV.CSVExport(data, {filename}, `top_${this.props.entity}`);
    }

    exportToXls(){
        this.getTimeseries().then(timeseries=>{
            const filename = CSV.CSVHeader(`top_${this.props.entity}`, this.state.sort.field, this.props.filter.global, this.props.parentEntityTitle);
            let data = this.getResults();
            data = CSV.expandTimelineFlat(data, timeseries, this.props.entity.replace(/s$/, '')+'_id');
            return CSV.XLSExport(data, {filename}, `top_${this.props.entity}`);
        })
    }


    expand(id) {
        const currentExpandedId = (id !== this.state.currentExpandedId) ? id : null;
        this.setState({
            currentExpandedId
        })
    }

    getResults(mode='top', limit){
        if(limit === undefined) {
            limit = this.getLimit();
        }
        let result;
        switch(this.props.entity){
            case 'imprints':
                result = this.props.imprints[mode] || [];
                break;
            case 'artists':
                result = this.props.artists[mode] || [];
                break;
            case 'products':
                result = this.props.products[mode] || [];
                break;
            case 'projects':
                result = this.props.projects[mode] || [];
                break;
            case 'playlists':
                result = this.props.playlists[mode] || [];
                break;
            case 'tracks':
            case 'tracks_in_playlist':
                result = this.props.tracks[mode] || [];
                break;
        }
        return Array.isArray(result) ? result.slice(0, limit) : result;
    }
    
    getTotals(mode='topTotal'){
        switch(this.props.entity){
            case 'imprints':
                return this.props.imprints[mode] || [];
            case 'artists':
                return this.props.artists[mode] || [];
            case 'products':
                return this.props.products[mode] || [];
            case 'projects':
                return this.props.projects[mode] || [];
            case 'playlists':
                return this.props.playlists[mode] || [];
            case 'tracks':
            case 'tracks_in_playlist':
                return this.props.tracks[mode] || [];
        }
    }

    getLoading(mode='top'){
        switch(this.props.entity){
            case 'imprints':
                return this.props.imprints[`${mode}Loading`] || false;
            case 'artists':
                return this.props.artists[`${mode}Loading`] || false;
            case 'products':
                return this.props.products[`${mode}Loading`] || false;
            case 'projects':
                return this.props.projects[`${mode}Loading`] || false;
            case 'playlists':
                return this.props.playlists[`${mode}Loading`] || false;
            case 'tracks':
            case 'tracks_in_playlist':
                return this.props.tracks[`${mode}Loading`] || false;
        }
    }


    setChartView(view){
        this.setState({view});
    }

    setSort(sort){
        this.setState({sort}, ()=>{
            this.props.getEntities({sorted: this.state.sort, filtered: this.state.filter, page: this.state.page}, false);
        })
    }
    
    getLimit(){
        const { filter } = this.state;
        let limitKeyIndex = findIndex(filter, item=>item.id == 'limit');
        return filter[limitKeyIndex].value;        
    }
    
    setLimit(limit){
    	let filter = Object.assign([], this.state.filter);
    	let limitKeyIndex = findIndex(filter, item=>item.id == 'limit');
    	filter[limitKeyIndex].value = limit.value;
    	filter.page = 0;
        this.setState({filter}, ()=>{
        	this.props.getEntities({sorted: this.state.sort, filtered: this.state.filter, page: this.state.page}, false);
        })
    }
    
    changePage(page) {
        this.setState({page}, ()=>{
            this.props.getEntities({sorted: this.state.sort, filtered: this.state.filter, page: this.state.page}, false);
        })
    }


    getDetails(id){
        switch(this.props.entity){
            case 'imprints':
                return this.props.imprints.imprint[id] || {};
            case 'artists':
                return this.props.artists.artist[id] || {};
            case 'products':
                return this.props.products.product[id] || {};
            case 'projects':
                return this.props.projects.product[id] || {};
                
            case 'playlists':
                return this.props.playlists.timeline[id] || {};
            case 'tracks':
            case 'tracks_in_playlist':
                return this.props.tracks.track[id] || {};
        }
    }

    handleChange(event) {
        this.setState({value: event.target.value});
    }

    renderPlaylistAnalisysLink() {
        const title = ['track_artists', 'product_artists'].includes(this.props.parentEntity) ? 'Artists' : capitalize(this.props.parentEntity);
        return (
          <div className="playlist-details-link">
            <PlaylistAnalysisLink
              title={`${title} Playlist Analysis`}
              entity={this.props.parentEntity}
              id={this.props.parentEntityIDs}
            ></PlaylistAnalysisLink>
          </div>
        );
    }

    renderToolbar(views){
        const { entity, parentEntity, parentEntityIDs } = this.props;
        const defaultView = this.props.defaultView || 'table';
        const { view } = this.state;
        const limitOptions = this.limits.map(limit => ({
            label: limit,
            value: limit        
        })),
        currentLimit = find(this.state.filter, item=>item.id == 'limit'),
        limitValue = find(limitOptions, {value: currentLimit.value});
        
        const renderLimit = !(parentEntity && parentEntityIDs);

        let toolbar = [];
        if(defaultView !== 'cards') {
            
            toolbar.push(<div key="wrapper" className="ibox-action-holder">
            <div className="ibox-actions mode-select">
        	  <React.Fragment>Limit: <ReactSelect value={limitValue} options={limitOptions} onChange={this.setLimit} className="single-select select-limit"/></React.Fragment>
              <span className="sort-by">Sort by: </span>
              <ReactSelect value={this.state.sort} options={this.options} onChange={this.setSort} className="single-select"/>
              {/*
              <ConfirmClick confirmAction={this.exportToCsv} title="Export CSV" confirmClass="" confirmLabel={<span key="download" className="chart-icon download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />} confirmPrompt="These reports are for analytical purposes only and should not be used to pay artist royalties"></ConfirmClick>
              */}
              {view == 'table' && <ExportModal exportToCsv={this.exportToCsv} loading={this.getLoading()} />}
              {view != 'table' && <a key="download" title="Export" onClick={this.exportToXls} className="chart-icon download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />}

            </div>
            </div>)
            
        }
        else {
            toolbar.push(<div key="wrapper">
            <div key="sort" className="select-holder"><span className="select-label">Sort by</span>
                <ReactSelect value={this.state.sort} options={this.options} onChange={this.setSort} className="single-select"/>
            </div>
            </div>);
            
        }
        for (let view in views) {
            toolbar.push(<a key={view}><i className={`fa fa-${views[view]} ${this.state.view == view ? 'active' : ''}`} onClick={()=>this.setChartView(view)}></i></a>);
        }
        return toolbar;
    }

    getDateRange(){
        if(this.props.filter.global) {
            let { dateStart, dateEnd } = this.props.filter.global;
            return `${moment(dateStart).format('D MMM')} - ${moment(dateEnd).format('D MMM')}`;

        }
        else
            return '';

    }

    renderTrends(data){
        if(!data || !data.length)
            return null;

        const topEntity = maxBy(data, 'curr_units'),
            bestDay = maxBy(topEntity.stms_by_date, 'units'),
            worstDay = minBy(topEntity.stms_by_date, 'units');

        return <div>
            <h2 className="content-title capitalize">{topEntity.name}</h2>
            <h4 className="content-subtitle">has been you best performing entity with {convertToThousands(topEntity.curr_units)} streams</h4>
            <TrendDirection direction="up">
                <strong>{moment(bestDay.stream_date).format('Do MMMM')}</strong> was your best performing day with <span className="num">{convertToThousands(bestDay.units)} streams</span>
            </TrendDirection>
            <TrendDirection direction="down">
                <strong>{moment(worstDay.stream_date).format('Do MMMM')}</strong> was your worst performing day with <span className="num">{convertToThousands(worstDay.units)} streams</span>
            </TrendDirection>

        </div>;
    }

    renderTooltip() {
        let tooltip = [];
        tooltip.push(<span>
            {this.props.entity === 'products' && 
            <Tooltip 
                position="bottom" 
                message={`Sales on a UPC level start populating from the product release date, apart from Apple where there are Instant grat tracks available for streaming on Apple Music. Use the entity filters to group ISRCs to have the full project visibility.\n\n In addition, note that due to the nature of reporting from some DSPs, it is sometimes not possible for us to map an ISRC to a UPC. In these instances, these products will appear in your Admin panel for you to review.`} 
                tooltipClass="top-sections-tooltip"
            />}
            {this.props.entity === 'artists' && 
            <Tooltip 
                position="bottom" 
                message={`Total Artist sales on the Overview and Catalogue pages are for products and tracks where the artist is Primary Position One only. On the Artist Details page, Total sales are higher because they also include products and tracks where the artist is not the first Primary artist listed.`} 
                tooltipClass="top-sections-tooltip"
            />}
        </span>
        )

        return tooltip;
    }
    
    renderTitle() {
        let { title, filter, addTotal } = this.props;
        
        return title;
        
        let results = this.getResults();
        let split = title.split(" ");

        if(results && results.length) {
        	let total = results.length;
        	if(addTotal)
        		total--;
            title = `${split[0]} ${total} ${split[1]}`
        }
        else if(filter.global && filter.global.limit) {
            title = `${split[0]} ${filter.global.limit} ${split[1]}`;
        }
            
           
        return title;
    }

    render(){
    	const { includeTimeseries = true, externalLinks = false } = this.props;
        let views = {
            'table': 'table',
            'line': 'chart-line',
            'bar': 'chart-bar'
        },
        trends = (config.showTrends && this.props.trends) || false;
        const { showPlaylistAnalisysLink } = this.props;
        const showTooltip = this.props.entity === 'products' || this.props.entity === 'artists' ? true : false
        let topChartClass = '';
        const datasetResults = this.getResults('timeseries').datasets;

        if (datasetResults) {
            if (datasetResults.length > 20 && datasetResults.length <= 100) {
                topChartClass = 'top-chart-md'    
            } else if (datasetResults.length > 100 ) {
                topChartClass = 'top-chart-lg'    
            }
        }
        
        const resultsLoading = (this.getLoading('top') || this.getLoading('timeseries'));

        return <Box 
                title={this.renderTitle()} 
                // hasTooltip={showTooltip} 
                // loadingTooltip={this.renderTooltip()} 
                loadingMessage={LoadingMessage('overview')} 
                toolbar={this.renderToolbar(includeTimeseries ? views : {})} 
                className={`${this.props.entity} home-box`} 
                spinnerEnabled={(resultsLoading && this.state.view != 'table')} 
                showLink={showPlaylistAnalisysLink ? this.renderPlaylistAnalisysLink() : null}
                description={this.props.description}>
                <div className="row">
                    {trends && <div className={`col-xs-12 col-sm-3`}>
                        {this.renderTrends(this.getResults())}
                    </div>}
                    <div className={`col-xs-12 col-sm-${trends?9:12}`}>
                        {this.state.view == 'table' && 
                        <div>
                            <EntityTable entity={this.props.entity} parentEntity={this.props.parentEntity} results={this.getResults()} totals={this.getTotals()} resultsLoading={resultsLoading} currentPage={this.state.page} changePage={this.changePage} externalLinks={externalLinks} limit={this.getLimit()} />
                            {/* <a href={`catalog/${this.props.entity}`} className="all-entities-link">All {this.props.entity}</a> */}
                        </div>}
                        {this.state.view == 'vendors' && <EntityVendors entity={this.props.entity} parentEntity={this.props.parentEntity} results={this.getResults()} />}
                        {this.state.view == 'line' && <div className={`timeline-holder ${topChartClass}`}><div className="chart-block"><div className="chart-block-inner"><EntityTimeline mode="line" entity={this.props.entity} results={this.getResults('timeseries')} getTimeseries={this.getTimeseries} /></div></div></div>}
                        {this.state.view == 'bar' && <div className={`timeline-holder ${topChartClass}`}><EntityTimeline mode="bar" entity={this.props.entity} results={this.getResults('timeseries')} getTimeseries={this.getTimeseries} /></div>}
                    </div>
                </div>
            </Box>
    }
}

function mapStateToProps(state) {
    return {
        imprints: state.imprints,
        artists: state.artists,
        products: state.products,
        projects: state.projects,
        playlists: state.playlists,
        tracks: state.tracks,
        filter: state.filter
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        getEntities: (params, cache=true) => {
            switch(ownProps.entity) {
                case 'imprints':
                    return dispatch(imprintsActions.getTopImprints(params, cache, ownProps.addTotal));
                break;
                case 'artists':
                    return dispatch(artistsActions.getTopArtists(params, cache, ownProps.addTotal));
                    break;
                case 'products':
                    return dispatch(productsActions.getTopProducts(params, cache, ownProps.addTotal))
                    break;
                case 'projects':
                    return dispatch(projectsActions.getTopProjects(params, cache, ownProps.addTotal))
                    break;
                    
                case 'playlists':
                    return dispatch(playlistsActions.getTopPlaylists(params, null))
                    break;
                case 'tracks':
                    return dispatch(tracksActions.getTopTracks(params, cache, ownProps.addTotal))
                    break;
                case 'tracks_in_playlist':
                    return dispatch(tracksActions.getTopTracksInPlaylist(params, cache))
                    break;                    
            }
        },
        getTimeseries: (params, cache=true) => {
            switch(ownProps.entity) {
                case 'imprints':
                    return dispatch(imprintsActions.getTimeseriesImprints(params, cache));
                break;
                case 'artists':
                    return dispatch(artistsActions.getTimeseriesArtists(params, cache));
                    break;
                case 'products':
                    return dispatch(productsActions.getTimeseriesProducts(params, cache))
                    break;
                case 'projects':
                    return dispatch(projectsActions.getTimeseriesProjects(params, cache))
                    break;
                    
                case 'playlists':
                    return dispatch(playlistsActions.getTimeseriesPlaylists(params, null))
                    break;
                case 'tracks':
                    return dispatch(tracksActions.getTimeseriesTracks(params, cache))
                    break;
                case 'tracks_in_playlist':
                    return dispatch(tracksActions.getTimeseriesTracksInPlaylist(params, cache))
                    break;                    
            }
        },        
        getDetails: (id) => {
            switch(ownProps.entity) {
                case 'imprints':
                    dispatch(imprintsActions.getImprintDetails(id));
                    break;
                case 'artists':
                    dispatch(artistsActions.getArtistDetails(id));
                    break;
                case 'products':
                    dispatch(productsActions.getProductDetails(id))
                    break;
                case 'projects':
                    dispatch(projectsActions.getProjectDetails(id))
                    break;
                    
                case 'playlists':
                    dispatch(playlistsActions.getPlaylistTimeline(id))
                    break;
                case 'tracks':
                    dispatch(tracksActions.getTrackDetails(id))
                    break;
            }
        },
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(EntitiesList)
