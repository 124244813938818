import { find } from 'lodash';
import { statsConstants } from '../constants/stats';
import { statsService } from '../services/stats';
import { statsFormatter } from '../formatters/stats';

export const statsActions = {
    getTopStats,
    getCatalogStats,
    getVendorStats,
    getTopTerritoryStats,
    getTopTerritoryTimeseries,
    getTerritoryStats,
    getTerritoryTimeseries,    
    getDemographicsStats,
    getDemographicsTimeseries,
    getDevicesStats,
    getSourcesStats,
    getSourcesTimeseries,
    getTransactions,
    getTransactionsTimeseries,
    getStatementTotals,
    forceLoadAllTerritoriesData,
    forceLoadAllTopTerritoriesData,
    forceLoadAllVendorsData
};

function getTopStats(useCache) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        statsService.getTopStats(globalFilter)
            .then(
                stats => {
                    const data = statsFormatter.formatTop(stats);
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: statsConstants.GET_TOP_STATS_REQUEST } }
    function success(stats) { return { type: statsConstants.GET_TOP_STATS_SUCCESS, stats } }
    function failure(error) { return { type: statsConstants.GET_TOP_STATS_FAILURE, error } }
}

function getCatalogStats(useCache) {
    return (dispatch, getState) => {
        dispatch(request());
        
        const stats = useCache ? getState().stats.catalog : undefined;
        if(stats!==undefined) {
            dispatch(success(stats));
            return;
        }
        const globalFilter = getState().filter.global;
        statsService.getCatalogStats(globalFilter)
            .then(
                stats => {
                    const data = statsFormatter.formatCatalogStats(stats);
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: statsConstants.GET_CATALOG_STATS_REQUEST } }
    function success(stats) { return { type: statsConstants.GET_CATALOG_STATS_SUCCESS, stats } }
    function failure(error) { return { type: statsConstants.GET_CATALOG_STATS_FAILURE, error } }
}


function getVendorStats({mode, sort, vendor, contentType, limit, page}, useCache, parentEntity, parentEntityID) {
    return ( dispatch, getState ) => {
        dispatch(request());
        let options = {sorted: sort, page};
        if(parentEntity && parentEntityID) {
            options.filtered = [{
                id: parentEntity,
                value: parentEntityID
            },
            {
                id: 'limit',
                value: limit
            }]
        }
        else {
            options.filtered = [{
                id: 'limit',
                value: limit
            }]
        };
        
        let globalFilter = getState().filter.global,
            cache = useCache ? getState().stats.vendorCache : null, 
            getData = cache ? Promise.resolve(cache) : statsService.getVendorStats(options, globalFilter); 
            return getData.then(
                stats => {
                    let totals = stats.result.length > 0 ? find(stats.result, entity=>entity.customer == '') : {},
                            data = stats.result.filter(entity=>entity.customer != '');
                    data = statsFormatter.formatVendor(data, mode, vendor, contentType, totals);
                    dispatch(success(data, totals, stats));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: statsConstants.GET_VENDOR_STATS_REQUEST } }
    function success(stats, totals, cache) { return { type: statsConstants.GET_VENDOR_STATS_SUCCESS, stats, totals, cache } }
    function failure(error) { return { type: statsConstants.GET_VENDOR_STATS_FAILURE, error } }
}


function getTopTerritoryStats({mode, sort, limit, page}, useCache) {
    return ( dispatch, getState ) => {
        dispatch(request());
        let globalFilter = getState().filter.global,
            cache = useCache ? getState().stats.topTerritoryCache : null, 
            getData = cache ? Promise.resolve(cache) : statsService.getTopTerritoryStats({sorted: sort, limit, page}, globalFilter);
            return getData
            .then(
                stats => {
                    let totals = stats.result.length > 0 ? find(stats.result, entity=>entity.country_code == '') : {},
                            data = stats.result.filter(entity=>entity.country_code != '');
                    
                    data = statsFormatter.formatTopTerritory(data, totals);
                    dispatch(success(data, totals, stats));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: statsConstants.GET_TOP_TERRITORY_STATS_REQUEST } }
    function success(stats, totals, cache) { return { type: statsConstants.GET_TOP_TERRITORY_STATS_SUCCESS, stats, totals, cache } }
    function failure(error) { return { type: statsConstants.GET_TOP_TERRITORY_STATS_FAILURE, error } }
}

function getTopTerritoryTimeseries({mode, sort, limit, page, ids}, useCache) {
    return ( dispatch, getState ) => {
        dispatch(request());
        let globalFilter = getState().filter.global,
            cache = false, // useCache ? getState().stats.topTerritoryCache : null, 
            getData = cache ? Promise.resolve(cache) : statsService.getTopTerritoryTimeseries({ids, sorted: sort, limit, page}, globalFilter);
            return getData
            .then(
                stats => {
                    const data = statsFormatter.formatTopTerritoryTimeseries(stats.result);
                    dispatch(success(data, stats));
                    return stats;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: statsConstants.GET_TOP_TERRITORY_TIMESERIES_REQUEST } }
    function success(timeseries, cache) { return { type: statsConstants.GET_TOP_TERRITORY_TIMESERIES_SUCCESS, timeseries, cache } }
    function failure(error) { return { type: statsConstants.GET_TOP_TERRITORY_TIMESERIES_FAILURE, error } }
}


function getTerritoryStats(entity, id, sorted, limit, page) {
    return ( dispatch, getState ) => {
        dispatch(request());
        let globalFilter = getState().filter.global;
        const params = {filtered: {[entity]:id}, sorted, limit, page};
        const dataCall = entity == 'playlists' ? statsService.getTerritoryInPlaylistStats : statsService.getTerritoryStats;
        return dataCall(params, globalFilter) 
            .then(
                stats => {
                    let totals = stats.result.length > 0 ? find(stats.result, entity=>entity.country_code == '') : {},
                            data = stats.result.filter(entity=>entity.country_code != '');              
                    data = statsFormatter.formatTopTerritory(data, totals);
                    dispatch(success(data, totals));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: statsConstants.GET_TERRITORY_STATS_REQUEST } }
    function success(stats, totals, cache) { return { type: statsConstants.GET_TERRITORY_STATS_SUCCESS, stats, totals, cache } }
    function failure(error) { return { type: statsConstants.GET_TERRITORY_STATS_FAILURE, error } }
}

function getTerritoryTimeseries(entity, id, ids, sorted, limit, page) {
    return ( dispatch, getState ) => {
        dispatch(request());
        let globalFilter = getState().filter.global;
        const params = {filtered: {[entity]:id}, ids, sorted, limit, page};
        const dataCall = entity == 'playlists' ? statsService.getTerritoryInPlaylistTimeseries : statsService.getTerritoryTimeseries;
        return dataCall(params, globalFilter) 
            .then(
                stats => {
                    const data = statsFormatter.formatTopTerritoryTimeseries(stats.result);
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: statsConstants.GET_TERRITORY_TIMESERIES_REQUEST } }
    function success(stats, cache) { return { type: statsConstants.GET_TERRITORY_TIMESERIES_SUCCESS, stats, cache } }
    function failure(error) { return { type: statsConstants.GET_TERRITORY_TIMESERIES_FAILURE, error } }
}



function getDemographicsStats(entity, id, useCache) {
    return ( dispatch, getState ) => {
        dispatch(request());
        let globalFilter = getState().filter.global;
        return statsService.getDemographicsStats({[entity]:id}, globalFilter) 
            .then(
                stats => {
                    const data = statsFormatter.formatDemographics(stats);
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: statsConstants.GET_DEMOGRAPHICS_STATS_REQUEST } }
    function success(stats, cache) { return { type: statsConstants.GET_DEMOGRAPHICS_STATS_SUCCESS, stats, cache } }
    function failure(error) { return { type: statsConstants.GET_DEMOGRAPHICS_STATS_FAILURE, error } }
}

function getDemographicsTimeseries(entity, id, useCache) {
    return ( dispatch, getState ) => {
        dispatch(request());
        let globalFilter = getState().filter.global;
        return statsService.getDemographicsTimeseries({[entity]:id}, globalFilter) 
            .then(
                stats => {
                    const data = statsFormatter.formatDemographicsTimeseries(stats);
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: statsConstants.GET_DEMOGRAPHICS_TIMESERIES_REQUEST } }
    function success(stats, cache) { return { type: statsConstants.GET_DEMOGRAPHICS_TIMESERIES_SUCCESS, stats, cache } }
    function failure(error) { return { type: statsConstants.GET_DEMOGRAPHICS_TIMESERIES_FAILURE, error } }
}


function getDevicesStats(entity, id, useCache) {
    return ( dispatch, getState ) => {
        dispatch(request());
        let globalFilter = getState().filter.global;
        return statsService.getDevicesStats({[entity]:id}, globalFilter) 
            .then(
                stats => {
                    const data = statsFormatter.formatDevices(stats);
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: statsConstants.GET_DEVICES_STATS_REQUEST } }
    function success(stats, cache) { return { type: statsConstants.GET_DEVICES_STATS_SUCCESS, stats, cache } }
    function failure(error) { return { type: statsConstants.GET_DEVICES_STATS_FAILURE, error } }
}

function getSourcesStats(entity, id, useCache) {
    return ( dispatch, getState ) => {
        dispatch(request());
        let globalFilter = getState().filter.global;
        return statsService.getSourcesStats({[entity]:id}, globalFilter) 
            .then(
                stats => {
                    const data = statsFormatter.formatSources(stats);
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: statsConstants.GET_SOURCES_STATS_REQUEST } }
    function success(stats, cache) { return { type: statsConstants.GET_SOURCES_STATS_SUCCESS, stats, cache } }
    function failure(error) { return { type: statsConstants.GET_SOURCES_STATS_FAILURE, error } }
}

function getSourcesTimeseries(entity, id, useCache = false) {
    return ( dispatch, getState ) => {
        dispatch(request());
        let globalFilter = getState().filter.global;
        
        const cache = useCache ? getState().stats.sourcesTimeseries : null, 
        getData = cache ? Promise.resolve(cache) : statsService.getSourcesTimeseries({[entity]:id}, globalFilter);

        
        return  getData
            .then(
                stats => {
                    const data = statsFormatter.formatSourcesTimeseries(stats);
                    dispatch(success(data));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: statsConstants.GET_SOURCES_TIMESERIES_REQUEST } }
    function success(stats, cache) { return { type: statsConstants.GET_SOURCES_TIMESERIES_SUCCESS, stats, cache } }
    function failure(error) { return { type: statsConstants.GET_SOURCES_TIMESERIES_FAILURE, error } }
}

function getTransactions(entity, id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        statsService.getTransactions(entity, id, globalFilter)
            .then(
                stats => {
                    const data = statsFormatter.formatTransactions(stats.result);
                    dispatch(success(id, data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: statsConstants.GET_TRANSACTIONS_REQUEST } }
    function success(id, data) { return { type: statsConstants.GET_TRANSACTIONS_SUCCESS, id, data} }
    function failure(error) { return { type: statsConstants.GET_TRANSACTIONS_FAILURE, error } }
}

function getTransactionsTimeseries(entity, id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        statsService.getTransactionsTimeseries(entity, id, globalFilter)
            .then(
                stats => {
                    const data = statsFormatter.formatTransactionsTimeseries(stats.result);
                    dispatch(success(id, data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: statsConstants.GET_TRANSACTIONS_TIMESERIES_REQUEST } }
    function success(id, data) { return { type: statsConstants.GET_TRANSACTIONS_TIMESERIES_SUCCESS, id, data} }
    function failure(error) { return { type: statsConstants.GET_TRANSACTIONS_TIMESERIES_FAILURE, error } }
}


function getStatementTotals() {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        statsService.getStatementTotals(globalFilter)
            .then(
                stats => {
                    const data = statsFormatter.formatStatementTotals(stats.result);
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: statsConstants.GET_STATEMENT_TOTALS_REQUEST } }
    function success(data) { return { type: statsConstants.GET_STATEMENT_TOTALS_SUCCESS, data} }
    function failure(error) { return { type: statsConstants.GET_STATEMENT_TOTALS_FAILURE, error } }
}


function forceLoadAllData(force, key) {
    return dispatch => {
        dispatch(set(force));
    };

    function set(force) { return { type: statsConstants[key], force} }
}

function forceLoadAllTerritoriesData(force) {
    return forceLoadAllData(force, 'FORCE_LOAD_ALL_TERRITORIES_DATA');
}
function forceLoadAllTopTerritoriesData(force) {
    return forceLoadAllData(force, 'FORCE_LOAD_ALL_TOP_TERRITORIES_DATA');
}
function forceLoadAllVendorsData(force) {
    return forceLoadAllData(force, 'FORCE_LOAD_ALL_VENDORS_DATA');
}

